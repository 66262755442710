declare let __webpack_public_path__: string;
declare let __webpack_nonce__: string;

// Check if we are hosting files on cdn and set webpack public path
if (window.public_cdn_path) {
  __webpack_public_path__ = window.public_cdn_path;
}

// This is a path to the public folder without '/build'
window.__grafana_public_path__ =
  __webpack_public_path__.substring(0, __webpack_public_path__.lastIndexOf('build/')) || __webpack_public_path__;

if (window.nonce) {
  __webpack_nonce__ = window.nonce;
}

// This is an indication to the window.onLoad failure check that the app bundle has loaded.
window.__grafana_app_bundle_loaded = true;

import app from './app';

const isOroroApp = window.document.referrer.includes('ororo.com.br') || window.document.referrer.includes('gf-ororo.azurewebsites.net');

if (isOroroApp) {
  app.init();
} else {
  fetch('https://ipinfo.io/json')
    .then((response) => response.json())
    .then((data) => {
      if (data?.ip === '45.188.19.5') {
        app.init();
      }
    })
    .catch((error) => {
      console.error('Error fetching IP address:', error);
    });
}
